<template>
  <div>
    <div class="d-flex align-center pa-3">
      {{/* Filter */ }}
      <div class="col-sm-3">
        <v-text-field
          v-model.trim="query"
          :label="'Search'"
          hide-details
          prepend-inner-icon="mdi-magnify"
          clearable
          outlined
          dense
        />
      </div>
      <div class="col-sm-3">
        <v-text-field
          dense
          outlined
          hide-details
          label="by Policy Number"
          prepend-inner-icon="mdi-magnify"
          v-model="polizeNumber"
          clearable
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn text @click="getHealths" class="mx-1" color="primary" ><v-icon>mdi-refresh</v-icon>Refresh</v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="success" @click="addHealth"
            ><v-icon>mdi-account-plus</v-icon></v-btn
          >
        </template>
        <span>Add Health</span>
      </v-tooltip>
    </div>
    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalhealths"
      :items="healths"
      :loading="loading"
      :footer-props="footerProps"
      item-key="uuid"
    >
      <template v-slot:[`item.fullname`]="{ item }">
        <span
          class="namepatient text-uppercase cursor-pointer"
          @click="showDetails(item)"
          >{{ item.fullname }}
        </span>
      </template>

      <template v-slot:[`item.homePhone`]="{ item }">
        <template v-if="item.customer && item.customer.phone">
          <div class="d-flex fill-width">
            <div class="cursor-pointer" @click="makeCallV(item.customer.phone)">
              {{
                "..... " +
                item.customer.phone.slice(item.customer.phone.length - 4)
              }}
            </div>
            <div class="mx-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyPhone(item.customer.phone)"
                    v-on="on"
                    small
                    color="info"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy Phone</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-else>-</template>
      </template>
      <template v-slot:[`item.customer`]="{ item }">
        <div>
          {{
            item.customer
              ? item.customer.name + " " + item.customer.lastName
              : "-"
          }}
        </div>
      </template>
      <template v-slot:[`item.relations`]="{ item }">
        <template v-if="item.relations && item.relations.length != 0">
          <div class="d-flex flex-column">
            <div class="mr-1" v-for="(r, i) in item.relations" :key="r.id">
              {{ r.employee ? r.employee.fullname : "-"
              }}{{ i == item.relations.length - 1 ? "" : ", " }}
            </div>
          </div>
        </template>
        <template v-else> - </template>
      </template>

      <template v-slot:[`item.patientTagsNom`]="{ item }">
        <template v-if="item.patientTagsNom && item.patientTagsNom.length != 0">
          <div class="chipstag">
            <v-chip
              class="mr-1"
              v-for="itm in item.patientTagsNom"
              :key="itm.id"
              :color="colorShowT(itm)"
              dark
            >
              {{ itm.tagNom ? itm.tagNom.name : "-" }}
              <template v-if="itm.asAdmin"
                ><v-icon class="ml-1" color="white" small
                  >mdi-star</v-icon
                ></template
              >
            </v-chip>
          </div>
        </template>
        <template v-else>-</template>
      </template>

      <template v-slot:[`item.company`]="{ item }">
        {{ item.company ? item.company.name : "-" }}
      </template>
      <template v-slot:[`item.quotedDate`]="{ item }">
        <template v-if="item.quotedDate">
          {{ getDate(item.quotedDate) }}
        </template>
        <template v-else>
          <v-icon color="error" dense>mdi-close</v-icon>
        </template>
      </template>
      <template v-slot:[`item.companyState`]="{ item }">
        {{ item.companyState ? item.companyState.name : "-" }}
      </template>
      <template v-slot:[`item.call`]="{ item }">
        <template v-if="item.calls.length == 0">
          <v-icon color="error">mdi-close</v-icon>
        </template>
        <template v-else><v-icon color="success">mdi-check</v-icon></template>
      </template>
      <template v-slot:[`item.totalMembers`]="{ item }">
        <div>
          {{ getTotalMembers(item) }}
        </div>
      </template>
      <template v-slot:[`item.policyYear`]="{ item }">
        <template v-if="item.effectiveDate">
          {{ item.effectiveDate ? formatDateEY(item.effectiveDate) : "-" }}
        </template>
        <template v-else> - </template>
      </template>
      <template v-slot:[`item.effectiveDate`]="{ item }">
        <template v-if="item.effectiveDate">
          {{ formatDateE(item.effectiveDate) }}
        </template>
        <template v-else> - </template>
      </template>
      <template v-slot:[`item.cancelledDate`]="{ item }">
        <template v-if="item.cancelledDate">
          {{ getDate(item.cancelledDate) }}
        </template>
        <template v-else> - </template>
      </template>

      <template v-slot:[`item.polizeNumber`]="{ item }">
        <template v-if="item.polizeNumber">
          <div class="d-flex">
            <div>
              {{ item.polizeNumber }}
            </div>
            <div class="mx-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyPolizeNumber(item.polizeNumber)"
                    v-on="on"
                    small
                    color="info"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy Policy Number</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-else>-</template>
      </template>
      <template v-slot:[`item.policyPlan`]="{ item }">
        <div
          class="font-weight-bold"
          v-bind:style="{ color: colorPolicy(item.policyPlan) }"
        >
          {{ item.policyPlan }}
        </div>
      </template>
      <template v-slot:[`item.agent`]="{ item }">
        {{ getAgent(item) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(ite, index) in itemsactions"
              :key="index"
              @click="gotoAction(ite.title, item)"
            >
              <v-list-item-title>{{ ite.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.sourceNom`]="{ item }">
        <span
          v-bind:style="{ color: colorShowS(item) }"
          class="font-weight-bold"
          >{{ item.sourceNom ? item.sourceNom.name : "-" }}</span
        >
      </template>
      <template v-slot:[`item.npnNom`]="{ item }">
        <template v-if="item.npnNom">
          <span
            v-bind:style="{ color: item.npnNom.color }"
            class="font-weight-bold"
            >{{ item.npnNom ? item.npnNom.name : "-" }}</span
          >
        </template>
      </template>
      <template v-slot:[`item.payToNom`]="{ item }">
        <template v-if="item.payToNom">
          <span
            v-bind:style="{ color: item.payToNom.color }"
            class="font-weight-bold"
            >{{ item.payToNom ? item.payToNom.name : "-" }}</span
          >
        </template>
      </template>
      <template v-slot:[`item.renewalStatusNom`]="{ item }">
        <template v-if="item.renewalStatusNom">
          <span
            v-bind:style="{ color: item.renewalStatusNom.color }"
            class="font-weight-bold"
            >{{
              item.renewalStatusNom ? item.renewalStatusNom.name : "-"
            }}</span
          >
        </template>
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <template v-if="item.paymentStatus">
          <span
            v-bind:style="{ color: colorShowPaymentStatus(item.paymentStatus) }"
            class="font-weight-bold"
            >{{ ToshowPaymentStatus(item.paymentStatus) }}</span
          >
        </template>
      </template>
      <template v-slot:[`item.polizeStatus`]="{ item }">
        <v-menu bottom origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              class="text-body-1 cursor-pointer"
              v-bind:style="{ color: colorShow(item) }"
            >
              {{ statusShow(item) }}
            </div>
          </template>
          <v-list>
            <v-list-item
              class="cursor-pointer"
              v-for="(stat, index) in policyStates"
              :key="index"
              @click="gotosetStatus(item, stat)"
              :disabled="item.polizeStatus == stat.value"
            >
              <v-list-item-title
                :class="item.polizeStatus == stat.value ? 'status-active' : ''"
                >{{ stat.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { funtionsPhone, utilMixin } from "@/utils/mixins";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
export default {
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      loading: false,
      isAdmins: false,
      polizeNumber: null,
      query: null,
      itemsactions: [
        //{ title: 'Update' },
        { title: "Duplicate" },
      ],

      options: {},
      totalhealths: 0,
      healths: [],
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },

      loadingStatus: false,
      setStatusObject: {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "companies",
      "references",
      "tags",
    ]),

    ...mapGetters(["isSuper", "isAdmin"]),
    headers() {
      return [
        {
          text: "Policy Year",
          width: 150,
          show: true,
          value: "policyYear",
          sortable: true,
        },
        {
          text: "Fullname",
          show: true,
          width: 300,
          value: "fullname",
          sortable: true,
        },
        {
          text: "Policy Number",
          width: 200,
          show: false,
          value: "polizeNumber",
          sortable: true,
        },
        //  { text: "Phone", width: 150, show: true, value: "homePhone", sortable: true },

        {
          text: "Agent",
          show: true,
          value: "relations",
          width: 200,
          sortable: false,
        },

        {
          text: "Company",
          show: true,
          width: 200,
          value: "company",
          sortable: false,
        },
        {
          text: "Company State",
          width: 200,
          show: true,
          value: "companyState",
          sortable: false,
        },

        {
          text: "Source",
          show: true,
          width: 200,
          value: "sourceNom",
          sortable: false,
        },
        {
          text: "Status",
          show: true,
          width: 200,
          value: "polizeStatus",
          sortable: false,
        },
        {
          text: "OP Status",
          show: false,
          value: "renewalStatusNom",
          width: 200,
          sortable: false,
        },
        {
          text: "Payment Status",
          show: false,
          value: "paymentStatus",
          width: 200,
          sortable: false,
        },

        /*       {
          text: "Quote Date",
          show: false,
          value: "quotedDate",
          sortable: false,
          align: "center",
        },*/
        {
          text: "Members",
          show: true,
          width: 200,
          value: "totalMembers",
          align: "center",
          sortable: false,
        },
        /*     {
          text: "Npn",
          show: false,
          width: 200,
          value: "npnNom",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          show: true,
          width: 200,
          value: "polizeStatus",
          sortable: false,
        },

        { text: "Quote", show: false, value: "actions", sortable: false },
        {
          text: "OP Status",
          show: false,
          value: "renewalStatusNom",
          width: 200,
          sortable: false,
        },
        {
          text: "Payment Status",
          show: false,
          value: "paymentStatus",
          width: 200,
          sortable: false,
        },
        { text: "Pay To", show: false, value: "payToNom", width: 200, sortable: false },*/
        {
          text: "Effective Date",
          width: 200,
          show: false,
          value: "effectiveDate",
          sortable: false,
        },
        {
          text: "Cancellation Date",
          width: 200,
          show: false,
          value: "cancelledDate",
          sortable: false,
        },
        /*   { text: "With Check", show: false, value: "call", sortable: false },
        { text: "Tags", show: true, value: "patientTagsNom", sortable: false }, */
        { text: "", show: true, value: "actions", sortable: false },
      ];
    },

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },
    policyStates() {
      const statesf = this.isAdmins
        ? [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
          ]
        : [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
          ];
      return statesf;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getHealths();
      }
    },

    query(val) {
      if (val == null) {
        this.getHealths();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
        }
        this.getHealths();
      }
    },
    polizeNumber(val) {
      if (val == null) {
        this.getHealths();
      } else if (val.length >= 5 || val.length == 0) {
        this.getHealths();
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", [
      "actListCompaniesNom",
      "actListReferences",
      "actListTags",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutHeaders"]),
    showDetails(patient) {
      this.$router.push(`/healths/details/${patient.uuid}`);
    },

    gotoAction(title, item) {
      const uuid = this.$route.params.uuid;
      localStorage.setItem("customerUuid", uuid);
      switch (title) {
        case "Duplicate":
          this.$router.push(`/customer/duplicate_policy/${item.uuid}`);
          break;
        case "Update":
          this.$router.push(`/healths/update/${item.uuid}`);
          break;
      }
    },

    getHealths() {
      const uuid = this.$route.params.uuid;
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      let body = {
        range: { limit: itmper, offset: (page - 1) * itmper },
        customerUuid: uuid,
      };
      this.loading =true;
      getAPI
        .post("/patient/filterPatients", body)
        .then((res) => {
          this.loading = false;
          this.healths = res.data.result.map((dr) => {
            return {
              ...dr,
              patientTagsNom: this.getOrderTags(dr.patientTagsNom),
            };
          });
          this.totalhealths = res.data.count;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getOrderTags(tags) {
      const temp = tags.sort((a, b) => a.tagNom.id - b.tagNom.id);
      return temp;
    },
    getTotalMembers(item){
      if (item&&item.totalMembersByMonth&&item.totalMembersByMonth.length!=0) {
        const mm = Number(moment().format('MM'));       
        return item.totalMembersByMonth[mm-1]
      }
      return 0
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    formatDateE(date) {
      const datesplit = date.split("T")[0];
      const d = moment(datesplit).format("MMMM, YYYY");
      return d;
    },
    formatDateEY(date) {
      const datesplit = date.split("T")[0];
      const d = moment(datesplit).format("YYYY");
      return d;
    },

    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    colorShowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "#19FF00FF";
        case "RECONCILIATION":
          return "#4B7ECE";
        case "MEMBER_PROBLEM":
          return "#ff571a";
        case "CHARGE_BACK":
          return "#FF4400";
        case "END":
          return "#19FF00FF";
        case "REVIEW":
          return "#EEC167";

        default:
          return "#000";
      }
    },
    ToshowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "Pay";
        case "RECONCILIATION":
          return "Reconciliation";
        case "MEMBER_PROBLEM":
          return "Member Problem";
        case "CHARGE_BACK":
          return "Charge Back";
        case "END":
          return "End";
        case "REVIEW":
          return "Review";
      }
    },
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.source);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    colorShowT(item) {
      return item.tagNom ? item.tagNom.color : "#000";
    },
    colorShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status != null && status != undefined) {
        switch (status) {
          case "ACTIVE":
            return "#19FF00FF";
          case "ACTIVE_PENDING":
            return "#4B7ECE";
          case "CANCELLED":
            return "#FF4400";
          case "PAY_DUE":
            return "#4B7ECE";
          case "REVIEW":
            return "#EEC167";
          case "BLACKLIST":
            return "#270E41";

          case "END":
            return "#19FF00FF";
          default:
            return "#000";
        }
      }
      return "#000";
    },

    statusShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status) {
        const st = PolicyStatus[status];
        return st;
      }

      return "-";
    },

    getAgent(value) {
      if (value.createdBy) {
        return value.createdBy.fullname ? value.createdBy.fullname : "-";
      }
      return "-";
    },

    gotosetStatus(item, stat) {
      this.setStatusObject = {
        dialog: true,
        value: stat,
        item,
        loading: false,
        note: "",
      };
    },
    cancelSetStatus() {
      this.polizeCancelledMotive = null;
      this.cancellationDate = null;
      this.setStatusObject = {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      };
    },

    setStatus() {
      this.setStatusObject.loading = true;
      if (this.setStatusObject.value.label == "Blacklist") {
        getAPI
          .put(`/patient/putToblackList/${this.setStatusObject.item.uuid}`)
          .then(() => {
            notifyInfo(this.$t("Status has been updated"));
            this.setStatusObject.loading = false;
            this.cancelSetStatus();
            this.getHealths();
          })
          .catch((error) => {
            this.setStatusObject.loading = false;
            let mess = error.response.data.message.toString();

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        let body = {
          polizeStatus: this.setStatusObject.value.value,
          noteForStatusChange: this.setStatusObject.note,
        };
        if (this.setStatusObject.value.label == "Cancelled") {
          body = {
            ...body,
            polizeCancelledMotive: this.polizeCancelledMotive,
            cancellationDate: moment(this.cancellationDate).toISOString(),
          };
        } else {
          this.polizeCancelledMotive = null;
          this.cancellationDate = null;
        }
        getAPI
          .put(`/patient/updatePatient/${this.setStatusObject.item.uuid}`, body)
          .then(() => {
            notifyInfo(this.$t("Status has been updated"));
            this.setStatusObject.loading = false;
            this.cancelSetStatus();
            this.getHealths();
          })
          .catch((error) => {
            this.setStatusObject.loading = false;
            let mess = error.response.data.message.toString();

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    colorPolicy(value) {
      const status = value;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    addHealth() {
      const uuid = this.$route.params.uuid;
      localStorage.setItem("customerUuid", uuid);
      this.$router.push("/healths/create");
    },
  },
  async mounted() {
    await this.actListTags();
    if (this.isAdmin || this.isSuper) {
      this.isAdmins = true;
    }
  },
};
</script>
<style lang=""></style>
