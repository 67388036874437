<template>
  <div>
    <v-card  :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text">
        <v-layout column>
          <div class="text-body-1 text-uppercase font-weight-bold">
            {{ details.name ? details.name : "" }}
            {{ details.lastName ? details.lastName : "" }}
          </div>
          <div class="d-flex flex-row align-center">
            <div class="text-caption font-weight-bold"  @click="makeCallV(details.phone)">Phone: {{ details.phone }}</div>
            <div class="mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="copyPhone(details.phone)"
                              v-on="on"
                              small
                              color="white"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copy Phone</span>
                        </v-tooltip>
                      </div>
          </div>
        </v-layout>
        <v-spacer></v-spacer>
        <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="white"
                  :disabled="showSmsGlobal"
                  icon
                  @click="openSMS"
                  ><v-icon>mdi-android-messages</v-icon></v-btn
                >
              </template>
              <span>Send Message</span>
            </v-tooltip>
            <template v-if="details.phone">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="white"
                    v-on="on"
                    icon
                    @click="makeCallV(details.phone)"
                  >
                    <v-icon>mdi-phone-forward</v-icon>
                  </v-btn>
                </template>
                <span>Make Call</span>
              </v-tooltip>
            </template>
            <send-email :user="details" />
        <v-divider class="mx-4" vertical />
        <v-tooltip transition="fade-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              :to="'/customers/update/' + $route.params.uuid"
              dark
              icon
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <span>Update customer</span>
        </v-tooltip>
        <v-tooltip transition="fade-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              :loading="loading"
              dark
              icon
              v-on="on"
              v-bind="attrs"
              @click="getCustomer"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          Update Details
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-tabs v-model="tabSelected" grow show-arrows>
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
            :disabled="tab.value == 'autos'"
            :href="tab.href"
          >
            <template>
              <v-icon left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </template>
          </v-tab>
          {{/* Customer Details */}}
          <v-tab-item value="details" v-if="details != null">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Full name:
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{ details.name ? details.name : "" }}
                    {{ details.lastName ? details.lastName : "" }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">DOB:</div>
                  <div class="text-body-1 text-uppercase">
                    {{ getDOB }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Gender:</div>
                  <div class="text-body-1 text-uppercase">
                    {{ details.isMale ? "Male" : "Female" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters class="mt-2">
              
                <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">Phone</div>
                <div class="text-body-1 text-uppercase">
                  <template v-if="details.phone">
                    <div class="d-flex">
                      <div
                        class="cursor-pointer"
                        @click="makeCallV(details.phone)"
                      >
                        {{ details.phone }}
                      </div>
                      <div class="mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="copyPhone(details.phone)"
                              v-on="on"
                              small
                              color="info"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copy Phone</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col> 
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Email:</div>
                  <div class="text-body-1 ">
                    {{ details.email ? details.email : "-" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">State</div>
                  <template v-if="loadingStates">
                    <div class="text-body-1 text-uppercase">...Loading States</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getState ? getState.name : "-" }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">City</div>
                  <template v-if="loadingCities">
                    <div class="text-body-1 text-uppercase">...Loading Cities</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getCity ? getCity.name : "-" }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">Address</div>
                  <div class="text-body-1 text-uppercase">
                    {{ details.address ? details.address.addressLine1 : "-" }}
                    {{
                      details.address && details.address.addressLine2
                        ? ", " + details.address.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">ZipCode</div>
                  <div class="text-body-1 text-uppercase">
                    {{ details.address ? details.address.zipCode : "-" }}
                  </div>
                </v-col>
              </v-row>
              <template v-if="details && details.otherPhones&&details.otherPhones.length!=0">
                <v-divider></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Other Phones:
              </div>
              <div>
                <v-chip class="ma-2" v-for="(item, index) in details.otherPhones" :key="index"> {{ item }} </v-chip>
              </div>
              </template>
            </v-container>
          </v-tab-item>
          {{/* Customer Healths */}}
          <v-tab-item value="healths" v-if="details != null">
            <healths-customer />
          </v-tab-item>
          <v-tab-item value="calls" v-if="details != null">
            <call-view :fromCustomer="true" :phoneCustomer="details.phone" />
          </v-tab-item>
          <v-tab-item value="notes" v-if="details != null">
           <CustomerNotes :customer="details"/>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";

import { notifyError } from "@/components/Notification";
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import HealthsCustomer from "./HealthsCustomer.vue";
import CallView from "../CallCenter/CallView.vue";
import CustomerNotes from "./components/CustomerNotes.vue";
import SendEmail from "@/components/emails/SendEmail.vue";
import { funtionsPhone, utilMixin } from '@/utils/mixins';
export default {
  components: { HealthsCustomer, CallView, CustomerNotes,SendEmail },
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      loading: false,
      loadingCities: false,
      details: { name: "as" },
      stateCities: [],
      tabSelected: "details",
      state: 0,
      tabs: [
        { value: "details", title: "Details", icon: "mdi-account", href: "#details" },
        {
          value: "healths",
          title: "Healths",
          icon: "mdi-medical-bag",
          href: "#healths",
        },
        {
          value: "autos",
          title: "Autos",
          icon: "mdi-car",
          href: "#autos",
        },
        {
          value: "calls",
          title: "Calls",
          icon: "mdi-phone",
          href: "#calls",
        },
        {
          value: "notes",
          title: "Notes",
          icon: "mdi-note-multiple",
          href: "#notes",
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
    };
  },
  watch: {
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },

    tabSelected:{
      handler(val){
        localStorage.setItem('customerTab',val)
      }
    }
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    ...mapState(["showSmsGlobal"]),
    getState() {
      if (this.states.length == 0) {
        return "-";
      } else {
        return this.states.find((s) => s.id == this.state);
      }
    },
    getCity() {
      if (this.details) {
        if (this.stateCities.length == 0) {
          return "-";
        } else {
          return this.stateCities.find((s) => s.id == this.details.address.city);
        }
      } else {
        return "-";
      }
    },
    getDOB() {
      if (this.details) {
        const dob = moment(this.details.birthDate).format("MM/DD/YYYY");
        return dob;
      }
      return "-";
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    ...mapMutations(["mutShowSms"]),
    getCustomer() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI("/customer/" + uuid)
        .then((res) => {
          this.details = res.data;
          this.state = res.data.address ? res.data.address.state : 0;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      //
    },

    openSMS() {
      localStorage.setItem("numberSMS", this.details.phone);
      localStorage.setItem("nameSMS", this.details.phone);
      this.mutShowSms(true);
    },

    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
  },
  async mounted() {
    await this.getCustomer();
    if (localStorage.getItem('customerTab')) {
      this.tabSelected=localStorage.getItem('customerTab')
    }

  },
};
</script>
<style lang=""></style>
